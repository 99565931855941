<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <h5>{{ $t('menu.' + this.gameName + 'Results') }}</h5>
                <DataTable
                    :lazy="true"
                    :value="items"
                    :paginator="true"
                    :rows="perPage"
                    :loading="loading"
                    class="p-mt-3"
                    :totalRecords="totalPages"
                    @page="onPage($event)"
                    @sort="onSort($event)"
                    @filter="onFilter($event)"
                    ref="datatable"
                    responsiveLayout="scroll"
                    :paginatorBottom="true"
                >
                    <template #header>
                        <div class="p-grid">
                            <div class="p-col-12 p-md-2">
                                <Calendar style="width: 100%" dateFormat="dd.mm.yy" :showTime="true" hourFormat="24" :showIcon="true" :showButtonBar="true" v-model="startDateString"></Calendar>
                            </div>
                            <div class="p-col-12 p-md-2">
                                <Calendar style="width: 100%" dateFormat="dd.mm.yy" :showIcon="true" :showButtonBar="true" v-model="endDateString"></Calendar>
                            </div>
                            <div class="p-col-12 p-md-2"><InputText style="width: 100%" type="text" v-model="roundNo" :placeholder="$t('results.roundNo')" /></div>
                            <div class="p-col-12 p-md-2 p-md-offset-4 p-text-right"><Button :label="$t('buttons.showResults')" class="p-button-raised p-button-success" @click="readItems()" /></div>
                        </div>
                    </template>
                    <Column
                        ><template #body="slotProps">
                            <span class="indexColumn">{{ (this.crtPage - 1) * this.perPage + slotProps.index + 1 }}</span>
                        </template>
                    </Column>
                    <Column field="roundDate" :header="$t('results.roundDate')" :sortable="true">
                        <template #body="{ data }"> {{ formatCSDateAndTime(data.roundDate) }} </template></Column
                    >
                    <Column field="roundNo" :header="$t('results.roundNo')" :sortable="true"></Column>
                    <Column field="drawNo" :header="$t('results.drawNo')">
                        <template #body="{ data }"> {{ data.drawNo != null ? data.drawNo.join(',') : '' }} </template>
                    </Column>
                    <Column field="bonusNo" :header="$t('results.bonusNo')">
                        <template #body="{ data }"> {{ data.bonusNo != null ? data.bonusNo.join(',') : '' }} </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </div>
</template>
<script>
import { BoVirtualsApi } from '../../../service/BoVirtualsApi';
import { uiSettings } from '../../../settings/uiSettings';
export default {
    name: 'VirtualsResults',
    data() {
        return {
            tableHeight: uiSettings.tableHeight,
            items: [],
            BoVirtualsApi: new BoVirtualsApi(),
            sort: '',
            crtPage: 0,
            startDateString: '',
            endDateString: '',
            roundNo: '',
            perPage: uiSettings.tableRows,
            loading: true,
            totalPages: 0,
        };
    },
    props: {
        gameName: String,
    },
    mounted() {
        let crtDate = new Date();
        let year = crtDate.getFullYear();
        let month = crtDate.getMonth() + 1;
        if (month <= 9) {
            month = '0' + month;
        }
        let day = crtDate.getDate();
        if (day <= 9) {
            day = '0' + day;
        }
        this.startDateString = year + '-' + month + '-' + day + 'T00:00';
        this.endDateString = year + '-' + month + '-' + day + 'T23:59';
        this.sort = 'roundDate|desc';
        this.readItems();
    },
    methods: {
        readItems(page = 1) {
            this.crtPage = page;
            this.loading = true;
            var startDate = new Date(this.startDateString);
            var endDate = new Date(this.endDateString);
            const params = {
                per_page: this.perPage,
                page: page,
                sort: this.sort,
                roundNo: this.notNullOrEmptyField(this.roundNo) ? this.roundNo : 0,
                startdate: this.formatApiDate(startDate),
                endDate: this.formatApiDate(endDate),
                game: this.gameName,
            };
            this.BoVirtualsApi.getGameResults(params)
                .then((response) => {
                    this.items = response.data.data;
                    this.totalPages = response.data.total;
                    this.loading = false;
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                });
        },
        onPage(event) {
            this.readItems(event.page + 1);
        },
        onSort(event) {
            let sortOrder = event.sortOrder == 1 ? 'asc' : 'desc';
            this.sort = event.sortField + '|' + sortOrder;
            this.readItems();
        },
    },
};
</script>
