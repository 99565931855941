import axios from 'axios'
import settings from '../settings/apis.js'
import {store} from '../store/index.js'

class BoVirtualsApi {
    constructor() {
        this.Api = axios.create({
            baseURL: settings[process.env.NODE_ENV].boApiUrl,
            withCredentials: false,

            headers: {
                'Content-Type': 'application/json',
                'X-Access-Token': store.getters['user/accessToken']
            }
        });
    }


    getGameResults(params) {
        return this.Api.get('/games/results', {params});
    }
}

export {
    BoVirtualsApi
}
